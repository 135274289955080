const Turbolinks = require("turbolinks")
Turbolinks.start()

Turbolinks.scroll = {}

document.addEventListener("turbolinks:load", () => {
  const elements = document.querySelectorAll(".persist-scroll");

  elements.forEach(function(element) {
    element.addEventListener("click", ()=> {
      Turbolinks.scroll["top"] = document.scrollingElement.scrollTop;
    });

    element.addEventListener("submit", ()=> {
      Turbolinks.scroll["top"] = document.scrollingElement.scrollTop;
    });

  });

  if (Turbolinks.scroll["top"]) {
    document.scrollingElement.scrollTo(0, Turbolinks.scroll["top"]);
  }

  Turbolinks.scroll = {};
})

function submitTurboForm() {
  event.preventDefault()
  Array.from(document.querySelectorAll(".load-change")).map(element => {
    element.classList.add("loading-blur")
  })

  const form = event.target
  const formData = new FormData(form)

  const request = new XMLHttpRequest()
  request.open(form.method, form.action)
  request.onload = function() {
    if (this.status >= 200 && this.status < 400) {
      var script = this.response
      eval(script)
    }
  }

  request.send(formData)
}
window.submitTurboForm = submitTurboForm
